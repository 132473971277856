<template>
    <div class="page">
        <div class="centercontainer">
            <a-form-model layout="vertical" :rules="dRules" :model="dFormData" ref="myForm">
                <a-form-model-item class="form-item" label="手机号码" ref="phone" prop="phone">
                    <a-input v-model="dFormData.phone" placeholder="请输入手机号码">
                        <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)" />
                    </a-input>
                </a-form-model-item>
                <a-form-model-item class="form-item" label="验证码" ref="smsCode" prop="smsCode">
                    <a-input v-model="dFormData.smsCode" placeholder="请输入验证码">
                        <a-button slot="addonAfter" type="primary" @click="getSmsCode">获取验证码</a-button>
                    </a-input>
                </a-form-model-item>
                <a-form-model-item class="form-item">
                    <a-button type="primary" @click="submitForm('myForm')">下一步</a-button>
                </a-form-model-item>
            </a-form-model>
        </div>
    </div>
</template>

<script>

    import { sms, getRetrievePasswordToken } from "@/api/api"
    import { strIsEmpty } from '@/utils/my-utils';
    import {isMobile} from '@/utils/validate';

    export default {
        name: "RetrievePassword",
        data(){
            return {
                dFormData: {
                    phone: '',
                    smsCode: '',
                },
                dRules: {
                    phone: [
                        { required: true, message: '请输入手机号码', trigger: 'blur' },
                    ],
                    smsCode: [
                        { required: true, message: '请输入手机验证码', trigger: 'blur' },
                    ]
                }
            }
        },
        methods: {
            pageInit:function () {

            },

            /** 获取校验码 */
            getSmsCode: function () {
                let that = this;
                if(strIsEmpty(this.dFormData.phone)){
                    this.$message.error('手机号码不能为空');
                    return;
                }else{
                    if(isMobile(this.dFormData.phone) == false){
                        this.$message.error('手机号码格式错误，请重新输入');
                        return;
                    }
                }
                let params = {};
                params.account = this.dFormData.phone;
                params.smsType = 2  ;
                params.loginType = 1;
                sms(params).then((res) => {
                    console.log('发送验证码返回结果 = ' + JSON.stringify(res));
                    if (res.success) {
                        if(res.code == 200){
                            this.$message.success('验证码已经发送');
                        }else{
                            // code : 1=短信已经发送，验证码10分钟有效，2=手机号已经注册，3=手机号码不合法或被屏蔽
                            switch (res.code) {
                                case 1:
                                    this.$message.error(res.message);
                                    break;
                                case 2:
                                    this.$message.error(res.message);
                                    break;
                                case 3:
                                    this.$message.error(res.message);
                                    break;
                            }
                        }
                    } else {
                        this.$message.error('获取校验码失败，请重试');
                    }
                });
            },
            /** 提交表单 */
            submitForm: function (formName) {
                let that = this;
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        that.retrieve();
                    } else {
                        return false;
                    }
                });
            },
            /** 下一步 */
            retrieve: function () {
                let that = this;
                if(isMobile(this.dFormData.phone) == false){
                    this.$message.error('手机号码格式错误，请重新输入');
                    return;
                }

                let params = {};
                params.account = this.dFormData.phone;
                params.smsCode = this.dFormData.smsCode;
                params.loginType = 1;
                getRetrievePasswordToken(params).then((res) => {
                    console.log('getRetrievePasswordToken 返回结果 = ' + JSON.stringify(res));
                    if (res.success) {
                        if(res.code == 200){
                            // 跳转到重置页面，并传送 重置token( retrieveToken )
                            let routerParams = {
                                phone: that.dFormData.phone,
                                token: res.result.token
                            };
                            //console.log('重置密码token = ' + res.result.token);
                            that.$router.push({name: "ResetPassword", params: routerParams});

                        }else{
                            // code: 1=该手机没有注册, 2=无效用户, 3=手机验证码错误
                            switch (res.code) {
                                case 1:
                                    this.$message.error(res.message);
                                    break;
                                case 2:
                                    this.$message.error(res.message);
                                    break;
                                case 3:
                                    this.$message.error(res.message);
                                    break;
                            }
                        }
                    } else {
                        this.$message.error('获取校验码失败，请重试');
                    }
                });

            },
        },
        created() {
            this.pageInit();
        },
    }
</script>

<style lang="less" scoped>
    .page{
        position: absolute;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px !important;
        background-color: #ffffff;
        .form-item{
            max-width: 300px;
        }
        .retrieve{
            margin-top: 60px;
        }
    }
</style>
